import React from "react";

import { steam } from "./steam.module.scss";

const Heart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M11 20C5 14 0 9 0 5s3-5 5-5 4 1 6 4c2-4 4-4 6-4s5 2 5 5c0 4-5 9-11 15"
    />
  </svg>
);

export default function Steam() {
  return (
    <div className={steam}>
      <Heart />
      <Heart />
      <Heart />
      <Heart />
    </div>
  );
}
